.App {
    text-align: center;
}

.Timeline-container {
    width: 90%;
    padding-left: 5%;
}

.Timeline-box {
    position: relative;
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
}

.Timeline-entry {
    position: absolute;
    background-color: red;
    cursor: pointer;
}

.Timeline-entry.selected {
    background-color: green;
}

.Timeline-timestamp-box {
    position: relative;
    width: 100%;
    height: 150px;
}

.Timeline-timestamp {
    position: absolute;
    transform: rotate(270deg) translate(-100%, 0);
    transform-origin: left;
    white-space: nowrap;
}

.EntryNotes-textarea {
    width: 90%;
    height: 300px;
}

.TrackButton-container {
    padding: 20px;
}

.TrackButton-button {
    font-size: 30px;
}

.TimeList-list {
    text-align: left;
    cursor: pointer;
}

.TimeList-list .selected {
    font-weight: bold;
}